import React, { useRef } from "react";
import { Link } from "react-router-dom";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";
import useDisableRightClick from "../../components/DisableRightClick.jsx";
import { useTheme } from '../../components/ThemeContext.jsx';

import LogoGrey from '../../assets/logo/logo_grey.svg';
import LogoWhite from '../../assets/logo/logo_white.svg';
import imgIntro from '../../assets/img/img_home_intro.webp';
import imgFormules from '../../assets/img/img_home_formules.webp';

import ThumbHomeDisciplines from "../../components/Thumbs/ThbHome/ThbHomeDiscipline.jsx";
import ThumbHomeExtras from "../../components/Thumbs/ThbHome/ThbHomeExtras.jsx";


function Home() {
  const { theme } = useTheme();
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  return (
    <>
      <section className={getClassName('section__intro')}>
        <h2 className={getClassName('section__intro__title')}>Ecole de danse
          {theme === 'light' ? (
            <img className={getClassName('section__intro__title__logo')} src={LogoGrey} alt='Logo 6th Art gris' ref={imageRef} />
            ) : (
            <img className={getClassName('section__intro__title__logo')} src={LogoWhite} alt='Logo 6th Art blanc' ref={imageRef} />
            )
          }
        </h2>
        <div className={getClassName('section__intro__banner')}>
          <img className={getClassName('section__intro__banner__image')} src={imgIntro} alt='banniere intro' ref={imageRef} />
        </div>
        <p className={getClassName('section__intro__txt')}>
          <strong className={getClassName('section__intro__txt__strong')}>6th Art</strong> est une école à l'image de la directrice. <br />
          <strong className={getClassName('section__intro__txt__strong')}>Pétillante</strong>, <strong className={getClassName('section__intro__txt__strong')}>bienveillante</strong> et <strong className={getClassName('section__intro__txt__strong')}>passionnée</strong>. <br />
          C'est au fil du temps que Maude a developpé sa pédagogie. <br />
          Persuadé que la passion de la danse vient d'un enseignement exigeant mais bienveillant. <br />
          Elle instaure un rapport de <strong className={getClassName('section__intro__txt__strong')}>confiance</strong> et de <strong className={getClassName('section__intro__txt__strong')}>respect</strong> entre élèves et professeur. <br />
          Tous travaillent sérieusement dans une ambiance détendue. <br />
          C'est dans cet <strong className={getClassName('section__intro__txt__strong')}>état d'esprit positif</strong> et d'<strong className={getClassName('section__intro__txt__strong')}>entraide</strong> que chaque cours se déroule, <br />
          alliant le <strong className={getClassName('section__intro__txt__strong')}>plaisir</strong> et la <strong className={getClassName('section__intro__txt__strong')}>progression</strong>.
        </p>
      </section>
      <section className={getClassName('section__disciplines')}>
        <h1 className={getClassName('section__disciplines__title')}>NOS DISCIPLINES</h1>
        <ThumbHomeDisciplines
          path='/disciplines'
          idEveil="kindergarden"
          idJazz="jazz"
          idContempo="contemporary"
          idBallet="ballet"
          idBat="floor-bar"
        />
      </section>
      <section className={getClassName('section__extras')}>
        <h1 className={getClassName('section__extras__title')}>Les p'tits +</h1>
        <ThumbHomeExtras
          path='/extras'
          idCompetition="competition"
          idWedding="wedding"
          idLesson="lesson"
        />
      </section>
      <section className={getClassName('section__formules')}>
        <Link
          className={getClassName('section__formules__link')}
          to='/infosbooking#plan'
        >
          <h1 className={getClassName('section__formules__link__title')}>DECOUVREZ NOS FORMULES</h1>
        </Link>
        <img className={getClassName('section__formules__image')} src={imgFormules} alt="bannière des formules" ref={imageRef} />
      </section>
    </>
  )
};


export default Home;