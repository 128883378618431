import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { RiInstagramFill, RiFacebookCircleFill } from "react-icons/ri";
import { HiOutlinePhone, HiOutlineMapPin } from "react-icons/hi2";
import { IoIosMail } from "react-icons/io";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";

import GoogleMap from "./Map.jsx";


const validationSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-z -]+$/, "Le prénom n'est pas valide.")
    .required("Champ obligatoire"),
  lastName: Yup.string()
    .matches(/^[A-Za-z -]+$/, "Le nom n'est pas valide.")
    .required("Champ obligatoire"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Le numéro de téléphone doit contenir 10 chiffres")
    .required("Champ obligatoire"),
  email: Yup.string()
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "Le mail saisi n'est pas valide.")
    .email("Email invalide")
    .required("Champ obligatoire"),
  object: Yup.string().required("Champ obligatoire"),
  message: Yup.string().required("Merci de saisir un message."),
  acceptTerms: Yup.boolean().oneOf([true], "Vous devez accepter les termes afin d'envoyer votre formulaire.")
});


function ContactUs() {
  const getClassName = useResponsiveClassName();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Envoyer les données à Formspree
      const response = await fetch('https://formspree.io/f/mblryzdp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        console.log("Form data submitted:", values);
        // Réinitialiser le formulaire après soumission
        resetForm();
        alert("Votre message a été envoyé avec succès !");
      } else {
        console.error("Erreur lors de l'envoi du formulaire");
        alert("Une erreur est survenue. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire", error);
      alert("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className={getClassName(`contact__wrapper`)}>
        <section className={getClassName(`contact__section__infos`)}>
          <h1 className={getClassName(`contact__section__infos__title`)}>VOUS TENIR INFORMÉ</h1>
          <div className={getClassName(`contact__section__infos__container`)}>
            <p className={getClassName(`contact__section__infos__container__txt`)}>Chez 6th Art, nous aimons partager notre passion pour la danse avec vous ! Pour ne rien manquer de nos actualités, cours, événements et coulisses, suivez-nous sur nos réseaux sociaux : </p>
            <span className={getClassName(`contact__section__infos__container__socialnetworks`)}>
              <a
                className={getClassName(`contact__section__infos__container__socialnetworks__icon`)}
                href="https://www.instagram.com/6th.art73/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiInstagramFill />
              </a>
              <a
                className={getClassName(`contact__section__infos__container__socialnetworks__icon`)}
                href="https://www.facebook.com/6th.art73"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiFacebookCircleFill />
              </a>        
            </span>
            <p className={getClassName(`contact__section__infos__container__txt`)}>Votre avis compte beaucoup pour nous ! Si vous appréciez nos cours et nos spectacles, nous serions ravis que vous laissiez un avis sur Google. Vos retours nous aident à nous améliorer et à partager notre amour de la danse avec encore plus de monde. Merci pour votre soutien et à bientôt sur les réseaux sociaux !</p>
          </div>
        </section>
        <section className={getClassName(`contact__section__forms`)}>
          <h1 className={getClassName(`contact__section__forms__title`)}>NOUS CONTACTER</h1>
          <div className={getClassName(`contact__section__forms__bloc`)}>
            <div className={getClassName(`contact__section__forms__bloc__infos`)}>
              <article className={getClassName(`contact__section__forms__bloc__infos__contact`)}>
                <div className={getClassName(`contact__section__forms__bloc__infos__contact__address`)}>
                  <HiOutlineMapPin
                    className={getClassName(`contact__section__forms__bloc__infos__contact__address__icon`)}
                  />
                  <p className={getClassName(`contact__section__forms__bloc__infos__contact__address__txt`)}>
                    74 chemin du martinet
                    <br />
                    73420 - Drumettaz-Clarafond
                  </p>
                </div>
                <div className={getClassName(`contact__section__forms__bloc__infos__contact__phone`)}>
                  <HiOutlinePhone
                    className={getClassName(`contact__section__forms__bloc__infos__contact__phone__icon`)}
                  />
                  <Link
                      className={getClassName(`contact__section__forms__bloc__infos__contact__phone__link`)}
                      to='tel:+33623881181'
                    >
                      +33 623 881 181
                  </Link>
                </div>
                <div className={getClassName(`contact__section__forms__bloc__infos__contact__mail`)}>
                  <IoIosMail
                    className={getClassName(`contact__section__forms__bloc__infos__contact__mail__icon`)}
                  />
                  <Link
                      className={getClassName(`contact__section__forms__bloc__infos__contact__mail__link`)}
                      to='mailto:contact@6th-art.com'
                    >
                      contact@6th-art.com
                  </Link>
                </div>
              </article>
              <aside className={getClassName(`contact__section__forms__bloc__infos__form`)}>
              <Formik
                  initialValues={{
                    firstName: '',
                    lastName: '',
                    phone: '',
                    email: '',
                    object: '',
                    message: '',
                    acceptTerms: false
                  }}
                  validationSchema={validationSchema}
                  validateOnChange={true}
                  validateOnBlur={true}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched, isSubmitting }) => (
                    <Form className={getClassName(`contact__section__forms__bloc__infos__form__section`)}>
                      <div className={getClassName(`contact__section__forms__bloc__infos__form__section__name`)}>
                        <span className={getClassName(`contact__section__forms__bloc__infos__form__section__name__item`)}>
                          <Field 
                            className={`${getClassName(`contact__section__forms__bloc__infos__form__section__name__item__input`)} ${touched.firstName && errors.firstName ? 'input-error' : ''}`}
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Prénom"
                          />
                          <ErrorMessage name="firstName" component="div" className="error-message" />
                        </span>
                        <span className={getClassName(`contact__section__forms__bloc__infos__form__section__name__item`)}>
                          <Field
                            className={`${getClassName(`contact__section__forms__bloc__infos__form__section__name__item__input`)} ${touched.lastName && errors.lastName ? 'input-error' : ''}`}
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Nom"
                          />
                          <ErrorMessage name="lastName" component="div" className="error-message" />
                        </span>
                      </div>
                      <div className={getClassName(`contact__section__forms__bloc__infos__form__section__phone`)}>
                        <span className={getClassName(`contact__section__forms__bloc__infos__form__section__phone__item`)}>
                          <Field
                            className={`${getClassName(`contact__section__forms__bloc__infos__form__section__phone__item__input`)} ${touched.phone && errors.phone ? 'input-error' : ''}`}
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder="Téléphone"
                          />
                          <ErrorMessage name="phone" component="div" className="error-message" />
                        </span>
                        <span className={getClassName(`contact__section__forms__bloc__infos__form__section__phone__item`)}>
                          <Field
                            className={`${getClassName(`contact__section__forms__bloc__infos__form__section__phone__item__input`)} ${touched.email && errors.email ? 'input-error' : ''}`}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                          />
                          <ErrorMessage name="email" component="div" className="error-message" />
                        </span>
                      </div>
                      <div className={getClassName(`contact__section__forms__bloc__infos__form__section__object`)}>
                        <Field
                          className={`${getClassName(`contact__section__forms__bloc__infos__form__section__object__input`)} ${touched.object && errors.object ? 'input-error' : ''}`}
                          type="text"
                          id="object"
                          name="object"
                          placeholder="Sujet de votre demande"
                        />
                        <ErrorMessage name="object" component="div" className="error-message" />
                      </div>
                      <div className={getClassName(`contact__section__forms__bloc__infos__form__section__message`)}>
                        <Field
                          as="textarea"
                          className={`${getClassName(`contact__section__forms__bloc__infos__form__section__message__input`)} ${touched.message && errors.message ? 'input-error' : ''}`}
                          id="message"
                          name="message"
                          placeholder="Rédigez votre demande ici"
                        />
                        <ErrorMessage name="message" component="div" className="error-message" />
                      </div>
                      <div className={getClassName(`contact__section__forms__bloc__infos__form__section__checkbox`)}>
                        <Field
                          type="checkbox"
                          id="acceptTerms"
                          name="acceptTerms"
                          className={`${touched.acceptTerms && errors.acceptTerms ? 'input-error' : ''}`}
                        />
                        <label
                          className={getClassName(`contact__section__forms__bloc__infos__form__section__checkbox__label checkbox__acceptTerms`)}
                          htmlFor="acceptTerms"
                        >
                          J’accepte que 6th Art collecte les données de ce formulaire.
                        </label>
                      </div>
                      <div className={getClassName(`contact__section__forms__bloc__infos__form__section__submit`)}>
                        <button
                          type="submit"
                          className={getClassName(`contact__section__forms__bloc__infos__form__section__submit__button`)}
                          disabled={isSubmitting}
                        >
                          ENVOYER
                        </button>
                      </div>
                      <ErrorMessage name="acceptTerms" component="div" className="error-message" />
                    </Form>
                  )}
                </Formik>
              </aside>
            </div>
          </div>
        </section>
      </div>
      <section className={getClassName(`contact__section__maps`)}>
        <GoogleMap />
      </section>
    </>
  )
};


export default ContactUs;