import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { RiInstagramFill, RiFacebookCircleFill, RiCopyrightLine } from "react-icons/ri";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";
import useDisableRightClick from "../../DisableRightClick.jsx";

import LogoGrey from '../../../assets/logo/logo_grey.svg';


const Footer = () => {
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  return (
    <footer className={getClassName('footer')}>
      <section className={getClassName('footer__container')}>
        <div className={getClassName('footer__container__links')}>
          <div className={getClassName('footer__container__links__logo')}>
            <img className={getClassName('footer__container__links__logo_image')} src={LogoGrey} alt="Logo 6th Art gris" title="Logo 6th Art" ref={imageRef} />
          </div>
          <div className={getClassName('footer__container__links__general')}>
            <h4 className={getClassName('footer__container__links__general__title')}>Général</h4>
            <div className={getClassName('footer__container__links__general__items')}>
              <Link
                className={getClassName('footer__container__links__general__items__link')}
                to='/school'
              >
                L' Ecole
              </Link>
              <Link
                className={getClassName('footer__container__links__general__items__link')}
                to='/disciplines'
              >
                Nos Disciplines
              </Link>
              <Link
                className={getClassName('footer__container__links__general__items__link')}
                to='/extras'
              >
                Les p'tits +
              </Link>
              <Link
                className={getClassName('footer__container__links__general__items__link')}
                to='/infosbooking'
              >
                Infos & Réservation
              </Link>
              <Link
                className={getClassName('footer__container__links__general__items__link')}
                to='/events'
              >
                Actualités
              </Link>
              <Link
                className={getClassName('footer__container__links__general__items__link')}
                to='/whoiam'
              >
                A Propos
              </Link>
            </div>
          </div>
          <div className={getClassName('footer__container__links__contact')}>
            <h4 className={getClassName('footer__container__links__contact__title')}>Nous contacter</h4>
            <div className={getClassName('footer__container__links__contact__items')}>
              <div className={getClassName('footer__container__links__contact__items__infos')}>
                <Link
                  className={getClassName('footer__container__links__contact__items__infos__mail')}
                  to='mailto:contact@6th-art.com'
                >
                  contact@6th-art.com
                </Link>
                <p className={getClassName('footer__container__links__contact__items__infos__address')}>
                  74 chemin du martinet - 73420 Drumettaz-Clarafond
                </p>
              </div>
              <div className={getClassName('footer__container__links__contact__items__socialnetworks')}>
                <p className={getClassName('footer__container__links__contact__items__socialnetworks__txt')}>
                  Pour vous tenir informé, suivez-nous sur nos réseaux : 
                </p>
                <div className={getClassName('footer__container__links__contact__items__socialnetworks__logos')}>
                  <a 
                    className={getClassName('footer__container__links__contact__items__socialnetworks__logos__insta')}
                    href="https://www.instagram.com/6th.art73/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiInstagramFill
                      className={getClassName('footer__container__links__contact__items__socialnetworks__logos__insta__icon')}
                    />
                  </a>
                  <a
                    className={getClassName('footer__container__links__contact__items__socialnetworks__logos__facebook')}
                    href="https://www.facebook.com/6th.art73"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <RiFacebookCircleFill
                      className={getClassName('footer__container__links__contact__items__socialnetworks__logos__facebook__icon')}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={getClassName('footer__infos')}>
        <div className={getClassName('footer__infos__container')}>
          <Link
            className={getClassName('footer__infos__container__link')}
            to='/generalinfos#legalNotice'
          >
            MENTIONS LEGALES
          </Link>
          <Link
            className={getClassName('footer__infos__container__link')}
            to='/generalinfos#privacyPolicy'
          >
            POLITIQUE DE CONFIDENTIALITE
          </Link>
          <Link
            className={getClassName('footer__infos__container__link')}
            to='/generalinfos#generalTermsOfUse'
          >
            CGV
          </Link>
        </div>
        <p className={getClassName('footer__infos__copyright')}>
          <RiCopyrightLine className={getClassName('footer__infos__copyright__icon')} />
          2024 Copyright - 6th Art - Tous droits réservés | Site créé par “Atelier Auguste Création”
        </p>
      </div>
    </footer>
  )
};


export default Footer;