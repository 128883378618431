import React, { useRef } from "react";

import useResponsiveClassName from "../ResponsiveClassName.jsx";
import useDisableRightClick from "../DisableRightClick.jsx";

import imgLNAffiche from "../../assets/img/imgShow/ln_affiche.webp";
import imgLN01 from "../../assets/img/imgShow/ln_img_01.webp";
import imgLN02 from "../../assets/img/imgShow/ln_img_02.webp";
import imgLN03 from "../../assets/img/imgShow/ln_img_03.webp";
import imgLN04 from "../../assets/img/imgShow/ln_img_04.webp";
import imgLN05 from "../../assets/img/imgShow/ln_img_05.webp";
import imgLN06 from "../../assets/img/imgShow/ln_img_06.webp";
import imgLN07 from "../../assets/img/imgShow/ln_img_07.webp";
import imgLN08 from "../../assets/img/imgShow/ln_img_08.webp";
import imgLN09 from "../../assets/img/imgShow/ln_img_09.webp";
import imgLN10 from "../../assets/img/imgShow/ln_img_10.webp";
import imgLN11 from "../../assets/img/imgShow/ln_img_11.webp";
import imgLN12 from "../../assets/img/imgShow/ln_img_12.webp";
import imgHeritageAffiche from "../../assets/img/imgShow/heritage_affiche.webp";
import imgHeritage01 from "../../assets/img/imgShow/heritage_img_01.webp";
import imgHeritage02 from "../../assets/img/imgShow/heritage_img_02.webp";
import imgHeritage03 from "../../assets/img/imgShow/heritage_img_03.webp";
import imgHeritage05 from "../../assets/img/imgShow/heritage_img_05.webp";
import imgHeritage06 from "../../assets/img/imgShow/heritage_img_06.webp";
import imgHeritage07 from "../../assets/img/imgShow/heritage_img_07.webp";
import imgHeritage08 from "../../assets/img/imgShow/heritage_img_08.webp";
import imgHeritage09 from "../../assets/img/imgShow/heritage_img_09.webp";
import imgHeritage10 from "../../assets/img/imgShow/heritage_img_10.webp";
import imgHeritage11 from "../../assets/img/imgShow/heritage_img_11.webp";
import imgHeritage12 from "../../assets/img/imgShow/heritage_img_12.webp";


const showData = {
  lanouvelle: {
    id: "lanouvelle",
    year: "2021 - 2022",
    poster: imgLNAffiche,
    images: [
      imgLN01,
      imgLN02,
      imgLN03,
      imgLN04,
      imgLN05,
      imgLN06,
      imgLN07,
      imgLN08,
      imgLN09,
      imgLN10,
      imgLN11,
      imgLN12,
    ]
  },
  heritage: {
    id: "heritage",
    year: "2022 - 2023",
    poster: imgHeritageAffiche,
    images: [
      imgHeritage01,
      imgHeritage02,
      imgHeritage03,
      imgHeritage05,
      imgHeritage06,
      imgHeritage07,
      imgHeritage08,
      imgHeritage09,
      imgHeritage10,
      imgHeritage11,
      imgHeritage12,
    ]
  }
};


const ThumbShow = ({ id }) => {
  const getClassName = useResponsiveClassName();
  const { year, poster, images } = showData[id];

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);


  if (!showData[id]) {
    return (
      <div>Invalid show ID</div>
    );
  }

  return (
    <>
      <div id={id} className={getClassName(`section__show__thumbnail thumbnail__${id}`)}>
        <h2 className={getClassName(`section__show__thumbnail__title  thumbnail__${id}__title`)}>{year}</h2>
        <div className={getClassName(`section__show__thumbnail__container thumbnail__${id}__container`)}>
          <img className={getClassName(`section__show__thumbnail__container__image thumbnail__${id}__container__image`)} src={poster} alt={id} ref={imageRef} />
        </div>
        <div className={getClassName(`section__show__thumbnail__portfolio thumbnail__${id}__portfolio`)}>
          {images && images.map(
            (image, index) => (
              <div className={getClassName(`section__show__thumbnail__portfolio__image`)}>
                <img key={index} className={getClassName(`section__show__thumbnail__portfolio__image__item thumbnail__${id}__portfolio__image__item__${index}`)} src={image} alt={`${id}__image__${index}`} ref={imageRef} />
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
};


export default ThumbShow;