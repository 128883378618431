import React, { useState } from "react";

import { HiOutlineChevronDown } from "react-icons/hi2";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";


const generalInfosData = {
  legalNotice: {
    id: "legalNotice",
    title: `MENTIONS LEGALES`,
    content: `<p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du site 6th Art l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
    <br />
    <h3>1. Edition du site</h3>
    <p>Le présent site, accessible à l’URL www.6th-art.com (le « Site »), est édité par : La société Atelier Auguste Création (EIRL), représenté par son dirigeant Julien DUMAS de VAULX résidant 169 rue de Vars 73310 Chindrieux, de nationalité Française (France), né(e) le 31/12/1984,  inscrite au R.C.S. de CHAMBERY sous le numéro RCS Chambery 927535856,</p>
    <br />
    <h3>2. Hébergement</h3>
    <p className='content__bloc__txt'>Le Site est hébergé par la société O2Switch, situé 222 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, (contact téléphonique ou email : (+33) 444 446 040).</p>
    <br />
    <h3>3. Directeur de publication</h3>
    <p>Le Directeur de la publication du Site est Atelier Auguste Création.</p>
    <br />
    <h3>4. Nous contacter</h3>
    <p>Par téléphone : (+33) 623 881 181</p>
    <p>Par email : contact@6th-art.com</p>
    <p>Par courrier : 74 chemin du martinet 73420 Drumettaz-Clarafond</p>
    <br />
    <h3>5. Données personnelles</h3>
    <p'>Le traitement de vos données à caractère personnel est régi par notre Charte du respect de la vie privée, disponible depuis la section "Charte de Protection des Données Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 («RGPD»).</p>`,
  },
  privacyPolicy: {
    id: "privacyPolicy",
    title: `POLITIQUE DE CONFIDENTIALITÉ`,
    content: `<h3>1. Introduction</h3>
    <p>Bienvenue sur le site web de 6th Art, école de danse située à Drumettaz-Clarafond. Nous attachons une grande importance à la protection de votre vie privée et de vos données personnelles. La présente politique de confidentialité a pour but de vous informer sur les types de données que nous collectons, la manière dont nous les utilisons et les mesures que nous prenons pour les protéger.</p>
    <br />
    <h3>2. Informations Collectées</h3>
    <p>Nous collectons différentes types d'informations personnelles lorsque vous interagissez avec notre site web, notamment :
      <li>Nom et prénom</li>
      <li>Adresse e-mail</li>
      <li>Numéro de téléphone</li>
      <li>Informations de paiement (pour les inscriptions en ligne)</li>
      <li>Données de navigation (cookies)</li>
    </p>
    <br />
    <h3>3. Utilisation des Informations</h3>
    <p>Les informations personnelles que nous collectons sont utilisées pour :
      <li>Traiter vos inscriptions aux cours de danse</li>
      <li>Gérer vos réservations et paiements</li>
      <li>Communiquer avec vous concernant nos services, actualités et événements</li>
      <li>Répondre à vos questions et demandes d'information</li>
      <li>Améliorer notre site web et nos services</li>
    </p>
    <br />
    <h3>4. Partage des Informations</h3>
    <p>Nous ne partageons pas vos informations personnelles avec des tiers, sauf dans les cas suivants :
      <li>Prestataires de services tiers qui nous aident à traiter les paiements ou à fournir d'autres services (sous réserve qu'ils respectent nos standards de protection des données)</li>
      <li>Lorsque la loi nous y oblige ou en réponse à une demande légale des autorités compétentes</li>
    </p>
    <br />
    <h3>5. Protection des Données</h3>
    <p>Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre toute perte, utilisation abusive, accès non autorisé, divulgation, altération ou destruction. Ces mesures incluent des technologies de cryptage et des contrôles d'accès stricts.</p>
    <br />
    <h3>6. Utilisation des Cookies</h3>
    <p>Nous utilisons des cookies pour améliorer votre expérience de navigation sur notre site web. Les cookies sont de petits fichiers texte stockés sur votre appareil qui nous aident à : 
      <li>Analyser l'utilisation du site web et améliorer sa performance</li>
      <li>Offrir des fonctionnalités de personnalisation</li>
      <li>Gérer les sessions de connexion</li>
    Vous pouvez gérer vos préférences en matière de cookies via les paramètres de votre navigateur.</p>
    <br />
    <h3>7. Droits des Utilisateurs</h3>
    <p>Conformément à la législation en vigueur, vous disposez des droits suivants concernant vos données personnelles :
      <li>Droit d'accès : Vous pouvez demander l'accès à vos données personnelles que nous détenons.</li>
      <li>Droit de rectification : Vous pouvez demander la correction de toute donnée inexacte ou incomplète.</li>
      <li>Droit à l'effacement : Vous pouvez demander la suppression de vos données personnelles.</li>
      <li>Droit à la limitation du traitement : Vous pouvez demander la limitation du traitement de vos données personnelles.</li>
      <li>Droit à la portabilité : Vous pouvez demander à recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine.</li>
    Pour exercer ces droits, veuillez nous contacter à l'adresse e-mail suivante : [adresse e-mail de contact].</p>
    <br />
    <h3>8. Modifications de la Politique de Confidentialité</h3>
    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur cette page. Nous vous encourageons à consulter régulièrement cette politique pour rester informé des éventuelles mises à jour.</p>
    <br />
    <h3>9. Contact</h3>
    <p>Pour toute question ou préoccupation concernant notre politique de confidentialité, veuillez nous contacter à : </ p> 
    <p>École de Danse - 6th Art</p>
    <p>Adresse : 74 chemin du martinet 73420 Drumettaz-Clarafond</p>
    <p>Téléphone : (+33) 623 881 181</p>
    <p>E-mail : contact@6th-art.com</p>`,
  },
  generalTermsOfUse: {
    id: "generalTermsOfUse",
    title: `CONDITIONS GÉNÉRALES D’UTILISATION`,
    content: 
    `<h2 className='collapse__content__bloc__title'>En vigueur au 01/06/2024</h2>
    <p className='collapse__content__bloc__txt'>Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement juridique des modalités de mise à disposition du site et des services par Atelier Auguste Création et de définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ». Les présentes CGU sont accessibles sur le site à la rubrique «CGU». Toute inscription ou utilisation du site implique l'acceptation sans aucune réserve ni restriction des présentes CGU par l’utilisateur. Lors de l'inscription sur le site via le Formulaire d’inscription, chaque utilisateur accepte expressément les présentes CGU en cochant la case précédant le texte suivant : « Je reconnais avoir lu et compris les CGU et je les accepte ». En cas de non-acceptation des CGU stipulées dans le présent contrat, l'Utilisateur se doit de renoncer à l'accès des services proposés par le site. 6th Art se réserve le droit de modifier unilatéralement et à tout moment le contenu des présentes CGU. </p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 1 : LES MENTIONS LÉGALES</h3>
    <p className='collapse__content__bloc__txt'>L'édition du site www.6th-art.com est assurée par la Société EIRL Atelier Auguste Création, immatriculée au RCS de Chambery sous le numéro 927 535 856, dont le siège social est situé au 169 rue de Vars 73310 Chindrieux Numéro de téléphone (+33) 769 020 475 Adresse e-mail : contact@atelierauguste-creation.fr. Le Directeur de la publication est : Julien DUMAS de VAULX L'hébergeur du site www.6th-art.com est la société O2Switch, dont le siège social est situé au 222 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, avec le numéro de téléphone : (+33) 444 446 040.</p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 2 : ACCÈS AU SITE</h3>
    <p className='collapse__content__bloc__txt'>Le site www.6th-art.com permet à l'Utilisateur un accès gratuit aux services suivants : Le site internet propose les services suivants : _______________ Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l'Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge. L’Utilisateur non membre n'a pas accès aux services réservés. Pour cela, il doit s’inscrire en remplissant le formulaire. En acceptant de s’inscrire aux services réservés, l’Utilisateur membre s’engage à fournir des informations sincères et exactes concernant son état civil et ses coordonnées, notamment son adresse email. Pour accéder aux services, l’Utilisateur doit ensuite s'identifier à l'aide de son identifiant et de son mot de passe qui lui seront communiqués après son inscription. Tout Utilisateur membre régulièrement inscrit pourra également solliciter sa désinscription en se rendant à la page dédiée sur son espace personnel. Celle-ci sera effective dans un délai raisonnable. Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du site ou serveur et sous réserve de toute interruption ou modification en cas de maintenance, n'engage pas la responsabilité de 6th Art ou Atelier Auguste Création. Dans ces cas, l’Utilisateur accepte ainsi ne pas tenir rigueur à l’éditeur de toute interruption ou suspension de service, même sans préavis. L'Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse email de l’éditeur communiqué à l’ARTICLE 1.</p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 3 : COLLECTE DES DONNÉES</h3>
    <p className='collapse__content__bloc__txt'>Le site assure à l'Utilisateur une collecte et un traitement d'informations personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles. L'Utilisateur exerce ce droit : · par mail à l'adresse email contact@atelierauguste-creation.fr</p>
    <br />
    <h3 className=collapse__content__bloc__title'>ARTICLE 4 : PROPRIÉTÉ INTELLECTUELLE</h3>
    <p className='collapse__content__bloc__txt'>Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d'auteur. L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite. Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle. Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.</p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 5 : RESPONSABILITÉ</h3>
    <p className='collapse__content__bloc__txt'>Les sources des informations diffusées sur le site www.6th-art.com sont réputées fiables mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions. Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site www.6th-art.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l’utilisation et de l’interprétation de l’information contenue dans ce site. L'Utilisateur s'assure de garder son mot de passe secret. Toute divulgation du mot de passe, quelle que soit sa forme, est interdite. Il assume les risques liés à l'utilisation de son identifiant et mot de passe. Le site décline toute responsabilité. Le site www.6th-art.com ne peut être tenu pour responsable d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation, à l’accès, ou au téléchargement provenant de ce site. La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d'un tiers.</p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 6 : LIENS HYPERTEXTES</h3>
    <p className='collapse__content__bloc__txt'>Des liens hypertextes peuvent être présents sur le site. L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira du site www.6th-art.com. Ce dernier n’a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.</p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 7 : COOKIES</h3>
    <p className='collapse__content__bloc__txt'>L’Utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement sur son logiciel de navigation. Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur de l’Utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site www.6th-art.com. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l’Utilisateur, d’autres restent. L’information contenue dans les cookies est utilisée pour améliorer le site www.6th-art.com. En naviguant sur le site, L’Utilisateur les accepte. L’Utilisateur doit toutefois donner son consentement quant à l’utilisation de certains cookies. A défaut d’acceptation, l’Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées. L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.</p>
    <br />
    <h3 className='collapse__content__bloc__title'>ARTICLE 8 : DROIT APPLICABLE ET JURIDICTION COMPÉTENTE</h3>
    <p className='collapse__content__bloc__txt'>La législation française s'applique au présent contrat. En cas d'absence de résolution amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître. Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.</p>`,
  }
};


function Collapse({ id }) {
  const getClassName = useResponsiveClassName();
  const data = generalInfosData[id];

  const { title, content } = data;
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  if (!data) {
    return (
      <p>Invalid ID provided</p>
    )
  };


  return (
    <div className={getClassName(`collapse`)}>
      <div className={getClassName(`collapse__item`)}>
        <h3 className={getClassName(`collapse__item__title`)}>{title}</h3>
        <button className={getClassName(`collapse__item__btn`)} onClick={toggleOpen}>
          <HiOutlineChevronDown
            className={getClassName( isOpen ? "chevron__rotate" : "chevron" )}
            alt={isOpen ? "close" : "open"}
          />
        </button>
      </div>
      { isOpen && (
          <div
            className={getClassName(`collapse__content`)}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )
      }
    </div>
  )
};


export default Collapse;