import React from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";

function WhoIAm() {
  const getClassName = useResponsiveClassName();

  return (
    <div className={getClassName(`whoiam__section`)}>
      <div className={getClassName(`whoiam__section__container`)}>
        <h2 className={getClassName(`whoiam__section__container__title`)}>Maude DUMAS</h2>
        <div className={getClassName(`whoiam__section__container__txt`)}>
          <p className={getClassName(`whoiam__section__container__txt__starter`)}>En quelques mots...</p>
          <p className={getClassName(`whoiam__section__container__txt__description`)}>Elle se dévoile un peu en revenant sur son parcours, qui fait d’elle, la personne qu’elle est aujourd’hui.
            <br />
            <br />
            Maude DUMAS commence à l’âge de 3 ans, dans une école dirigée par <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Sylvia MIKAEL</strong> à Roanne. Elle continuera son apprentissage à l’école AGORA tenu par <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Nelly & Emmanuelle PIERSON</strong>. Au travers de ces différents professeurs, elle s’initie et se passionne pour la danse classique, jazz et contemporaine.
            <br />
            <br />
            Dès son plus jeune âge, son rêve, bien qu’elle adore danser, n’est pas de devenir danseuse mais professeur. Son but est de transmettre, par son enseignement, sa passion et son amour de la « Danse » dans la bienveillance et l’accompagnement de chacun.
            <br />
            <br />
            Elle travaille notamment avec de grands noms tel que, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Isabelle RIDDEZ</strong>, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Olivier COSTE</strong>, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Beatrice BUFFIN</strong>, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Philippe ANOTA</strong>, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Rosella HIGHTOWER</strong>, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Bernard ESTRABAUT</strong>, <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Corinne LANSELLE</strong> et beaucoup d’autres.
            <br />
            <br />
            Après le BAC, elle part à Paris pour passer son EAT, puis son DE au sein de l’école Studio Harmonic, dirigée par <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Corinne LANSELLE</strong> et au Centre des Arts Vivants avec <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Martine CURTAT-CADET</strong>.
            <br />
            <br />
            Elle décide d’enseigner à Paris et travaille pour différentes structures privées et publiques.
            <br />
            <br />
            Elle travaillera pendant plus de 10 ans à la MJC de Créteil, où elle restructurera l’intégralité des cours de danse classique, jazz et contemporain pour finir avec plus de 180 élèves. Elle collaborera aussi avec <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>José MONTALVO</strong> & <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Mourad MERZOUKI</strong> ou encore <strong className={getClassName(`whoiam__section__container__txt__description__strong`)}>Béatrice MASSIN</strong>.
            <br />
            <br />
            Elle s’installe à Aix-les-bains pour suivre son mari et après une année enrichissante à la Verrière Espace Danse, elle décide de monter sa propre structure.
          </p>
        </div>
      </div>
      <a
        className={getClassName(`whoiam__section__button`)}
        href="https://lepetitreporterdu73.com/2021/01/drumettaz-clarafond-maude-dumas-grace-universelle/"
        target="_blank"
        rel="noopener noreferrer"
      >
        On parle de nous...
      </a>
    </div>
  )
};


export default WhoIAm;