import React, { useRef } from "react";
import PropTypes from "prop-types";

import useResponsiveClassName from "../ResponsiveClassName.jsx";
import useDisableRightClick from "../DisableRightClick.jsx";

import imgConcours from '../../assets/img/imgExtras/img_extras_concours.webp';
import imgMariage from '../../assets/img/imgExtras/img_extras_mariage.webp';
import imgCoursParticulier from '../../assets/img/imgExtras/img_extras_coursparticulier.webp';


const extrasData = {
  competition: {
    id: "competition",
    image: imgConcours,
    title: "Concours",
    description: "Chaque année parmi nos élèves, l’école donne la possibilité de participer à des concours de danse. Ce projet est proposé à des enfants qui ont choisi la formule perfectionnement ou passion. Les élèves doivent être capable de monter sur scène seul, en duo ou en groupe. Cette préparation s’effectue le plus souvent le week-end sous forme de cours particulier. La préparation aux concours demande beaucoup de rigueur pour pouvoir mettre en oeuvre sur scène ce qui a été travaillé au studio.",
  },
  wedding: {
    id: "wedding",
    image:imgMariage,
    title:"Mariage Evènements",
    description: "L’événement de votre vie approche ? Vous rêvez d’ouvrir le bal d’une façon qui vous ressemble, afin de vivre un moment inoubliable à partager avec vos proches. En tant que témoin, vous souhaitez réserver une activité personnalisée pour l’enterrement de vie de jeune fille ou de jeune homme des futurs mariés ou même les surprendre avec un flashmob le jour J ? Ne cherchez plus ! Nous sommes là pour faire de cet instant, un véritable moment de plaisir.",
  },
  lesson: {
    id: "lesson",
    image:imgCoursParticulier,
    title:"Cours Particulier",
    description: "Que vous soyez un débutant ou un danseur passionné en quête de perfectionnement, nos sessions privées offrent une attention personnalisée et un accompagnement individuel pour vous aider à réaliser vos objectifs de danse. Nos cours particuliers sont conçus pour répondre spécifiquement à vos besoins et aspirations en matière de danse. Vous bénéficierez d'un enseignement adapté à votre niveau de compétence, à votre style préféré et à vos objectifs personnels. Grâce à un cours individualisé et à des retours immédiats, vous progresserez rapidement dans votre technique, votre expression artistique et votre confiance en vous. Chaque leçon est soigneusement planifiée pour maximiser votre potentiel et vous permettre d'atteindre des niveaux de performance que vous n'auriez jamais cru possibles. Que vous souhaitiez perfectionner votre technique en danse classique, explorer les rythmes envoûtants du modern' jazz, repousser les limites de l'expression corporelle avec la danse contemporaine ou renforcer votre alignement et votre flexibilité avec la barre à terre, nos cours particuliers vous offrent la liberté de choisir le style qui vous passionne le plus. Offrez-vous l'opportunité de briller sur scène et de dépasser vos attentes en vous inscrivant à nos cours particuliers de danse dès aujourd'hui.",
  }
};


const ThumbExtras = ({ id, customClass }) => {
  const getClassName = useResponsiveClassName();
  const { image, title, description } = extrasData[id];

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  return (
    <>
      <div id={id} className={getClassName(`section__extras__thumbnail__${customClass} ${customClass}__${id}`)} >
        <div className={getClassName(`section__extras__thumbnail__${customClass}__overlay ${customClass}__${id}__overlay`)}>
          <h3 className={getClassName(`section__extras__thumbnail__${customClass}__overlay__title ${customClass}__${id}__title`)}>{title}</h3>
        </div>
        <div className={getClassName(`section__extras__thumbnail__${customClass}__container`)}>
          <img className={getClassName(`section__extras__thumbnail__${customClass}__container__image ${customClass}__${id}__image`)} src={image} alt={title} ref={imageRef} />
        </div>
        <div className={getClassName(`section__extras__thumbnail__${customClass}__txt`)}>
          <h3 className={getClassName(`section__extras__thumbnail__${customClass}__txt__title`)} >{title}</h3>
          <p className={getClassName(`section__extras__thumbnail__${customClass}__txt__description ${customClass}__${id}__description`)}>{description}</p>
        </div>
      </div>
    </>
  );
};

ThumbExtras.propTypes = {
  id: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};


export default ThumbExtras;