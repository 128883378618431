import React from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";

import ThumbDisciplines from "../../components/Thumbs/ThbDisciplines.jsx";


function Disciplines() {
  const getClassName = useResponsiveClassName();

  return (
    <section className={getClassName('wrapper__disciplines')}>
      <ThumbDisciplines
        id="kindergarden"
        customClass="discipline-page"
      />
      <ThumbDisciplines
        id="ballet"
        customClass="discipline-page"
      />
      <ThumbDisciplines
        id="jazz"
        customClass="discipline-page"
      />
      <ThumbDisciplines
        id="contemporary"
        customClass="discipline-page"
      />
      <ThumbDisciplines
        id="floor-bar"
        customClass="discipline-page"
      />
    </section>
  )
};


export default Disciplines;