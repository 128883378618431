import React from "react";
import { Link } from "react-router-dom";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";

import ThumbsDisciplines from "../../Thumbs/ThbDisciplines.jsx";


const ThumbHomeDisciplines = ({ idEveil, idJazz, idContempo, idBallet, idBat, path }) => {
  const getClassName = useResponsiveClassName();

  return (
    <div className={getClassName('section__disciplines__container')}>
      <Link
        className={getClassName('section__disciplines__container__link')}
        to={`${path}#${idEveil}`}
      >
        <ThumbsDisciplines
          id='kindergarden'
          customClass='home-page'
        />
      </Link>
      <Link
        className={getClassName('section__disciplines__container__link')}
        to={`${path}#${idJazz}`}
      >
        <ThumbsDisciplines
          id='jazz'
          customClass='home-page'
        />
      </Link>
      <Link
        className={getClassName('section__disciplines__container__link')}
        to={`${path}#${idContempo}`}
      >
        <ThumbsDisciplines
          id='contemporary'
          customClass='home-page'
        />
      </Link>
      <Link
        className={getClassName('section__disciplines__container__link')}
        to={`${path}#${idBallet}`}
      >
        <ThumbsDisciplines
          id='ballet'
          customClass='home-page'
        />
      </Link>
      <Link
        className={getClassName('section__disciplines__container__link')}
        to={`${path}#${idBat}`}
      >
        <ThumbsDisciplines
          id='floor-bar'
          customClass='home-page'
        />
      </Link>
    </div>
  );
};

export default ThumbHomeDisciplines;