import React from "react";
import { Link } from "react-router-dom";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";

import ThumbsExtras from "../ThbExtras.jsx";


const ThumbHomeExtras = ({ idCompetition, idWedding, idLesson, path }) => {
  const getClassName = useResponsiveClassName();

  return (
    <>
      <div className={getClassName('section__extras__container')}>
        <Link
          className={getClassName('section__extras__container__link')}
          to={`${path}#${idCompetition}`}
        >
          <ThumbsExtras
            id='competition'
            customClass='home-page'
          />
        </Link>
        <Link
          className={getClassName('section__extras__container__link')}
          to={`${path}#${idWedding}`}
        >
          <ThumbsExtras
            id='wedding'
            customClass='home-page'
          />
        </Link>
        <Link
          className={getClassName('section__extras__container__link')}
          to={`${path}#${idLesson}`}
        >
          <ThumbsExtras
            id='lesson'
            customClass='home-page'
          />
        </Link>
      </div>
    </>
  )
};


export default ThumbHomeExtras;