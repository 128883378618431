import React from 'react';
import { useMediaQuery } from 'react-responsive';


const FullScreen = ({ children}) => {
  const isFullScreen = useMediaQuery({ minWidth: 1921 });
  return (
    isFullScreen? children : null
  )
};

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1025, maxWidth: 1920 });
  return (
    isDesktop ? children : null
  )
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  return (
    isTablet ? children : null
  )
};

const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 991 });
  return (
    isNotMobile ? children : null
  )
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    isMobile ? children : null
  )
};


const ResponsiveWrapper = ({ children }) => {
  return (
    <div>
      <FullScreen>{children}</FullScreen>
      <Desktop>{children}</Desktop>
      <Tablet>{children}</Tablet>
      <Mobile>{children}</Mobile>
      <Default>{children}</Default>
    </div>
  );
};

export { Mobile, Tablet, Desktop, FullScreen, Default, ResponsiveWrapper };
