import React, { useRef, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi2";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";
import useDisableRightClick from "../../DisableRightClick.jsx";

import Logo from "../../../assets/logo/logo_grey.svg";
import homeBannerImg1 from "../../../assets/img/imgBanner/banner_home_img_1.webp";
import homeBannerImg2 from "../../../assets/img/imgBanner/banner_home_img_2.webp";
import homeBannerImg3 from "../../../assets/img/imgBanner/banner_home_img_3.webp";
import homeBannerImg4 from "../../../assets/img/imgBanner/banner_home_img_4.webp";
import homeBannerImg5 from "../../../assets/img/imgBanner/banner_home_img_5.webp";


function Slideshow() {
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);
  
  const pictures = [
    homeBannerImg1,
    homeBannerImg2,
    homeBannerImg3,
    homeBannerImg4,
    homeBannerImg5,
  ];

  const [currentSlide, nextSlide] =useState(0);
  const [prevSlide, setPrevSlide] = useState(null);

  const goToNextSlide = () => {
    nextSlide((currentSlide + 1) % pictures.length);
  };
  const goToPreviousSlide = () => {
    const newCurrentSlide = currentSlide - 1;
    if (newCurrentSlide < 0) {
      setPrevSlide(currentSlide);
      nextSlide(pictures.length - 1);
      return;
    }
    setPrevSlide(currentSlide);
    nextSlide(currentSlide - 1);
  };

  const goToSlide = (index) => {
    setPrevSlide(currentSlide);
    nextSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide();
    }, 7000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  });

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const showLogo = isMobile;


  return (
    <section className={getClassName('slider')}>
      <div className={getClassName('slider__images')}>
        {pictures.map((picture, index) => (
          <img
            key={index}
            src={picture}
            alt={`image__slider__${index}`}
            className={getClassName(`slider__images__picture ${
              index === currentSlide ? 'visible' : 'hidden'
            } ${
              index === prevSlide ? 'slide-out' : ''
            }`)}
            ref={imageRef}
          />
        ))}
      </div>
      {pictures.length > 1 && (
        <div className={getClassName('slider__action')}>
          <button className={getClassName('slider__action__btn')} title="button back">
            <HiOutlineChevronLeft className={getClassName('slider__action__btn__back')} alt="Back" onClick={goToPreviousSlide} />
          </button>
          <span className={getClassName('slider__action__index')}>
            {pictures && pictures.map((_, index) => (
              <span 
                key={index}
                className={getClassName(`slider__action__index__circle ${index === currentSlide ? 'on' : ''}`)}
                onClick={() => goToSlide(index)}
              />
            ))}
          </span>
          <button className={getClassName('slider__action__btn')} title="button next">
            <HiOutlineChevronRight className={getClassName('slider__action__btn__next')} alt="Next" onClick={goToNextSlide} />
          </button>
        </div>
      )}
      { showLogo && (
        <div className={getClassName('slider__logo')}>
          <img className={getClassName('slider__logo__img')} src={Logo} alt={Logo} title="logo 6th art" ref={imageRef} />
        </div>
      )}
    </section>
  )
};


export default Slideshow;