import React from "react";

import Menu from "../Menus/Menus.jsx";
import UserMenu from "../Menus/UserMenu.jsx";
import MobileMenu from "../Menus/MobileMenu.jsx";

import { Mobile, Tablet, Desktop, FullScreen } from "../../RespônsiveWrapper.jsx";


const Header = () => {

  
    return (
      <>
        <Mobile>
          <header className="mobile__header">
            <nav className="mobile__header__nav">
              <MobileMenu />
            </nav>
          </header>
        </Mobile>

        <Tablet>
          <header className="tablet__header">
            <nav className="tablet__header__nav">
              <Menu />
            </nav>
            <UserMenu />
          </header>
        </Tablet>

        <Desktop>
          <header className="desktop__header">
              <nav className="desktop__header__nav">
                <Menu />
              </nav>
              <UserMenu />
            </header>
        </Desktop>

        <FullScreen>
          <header className="fullscreen__header">
              <nav className="fullscreen__header__nav">
                <Menu />
              </nav>
              <UserMenu />
            </header>
        </FullScreen>
      </>
    )
};


export default Header;