import React from 'react';
import { APIProvider, Map } from '@vis.gl/react-google-maps';


function GoogleMap() {
  const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const defaultCenter = {
    lat: 45.66026306152344,
    lng: 5.914145469665527
  }


 return (
  <APIProvider apiKey={googleApiKey}>
    <Map
      defaultCenter={defaultCenter}
      defaultZoom={20}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
    >
    </Map>
  </APIProvider>
  );
}


export default GoogleMap;