import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiHome, HiUserPlus } from "react-icons/hi2";
import { Spin } from "hamburger-react";

import { useTheme } from "../../ThemeContext.jsx";
import ThemeToggleButton from "../Buttons/ThmTgleBtn.jsx";

import Menu from "../Menus/Menus.jsx";


const MobileMenu = () => {
  const { theme } = useTheme();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  
  return (
    <div className="mobile__header__navbar">
      <Link className={theme === 'light' ? (
          "mobile__header__navbar__icon"
          ) : (
          "mobile__header__navbar__icon__dark"
          )
        }
        to='/' >
        <HiHome onClick={closeMobileMenu}/>
      </Link>
      <Link className={theme === 'light' ? (
          "mobile__header__navbar__icon"
          ) : (
          "mobile__header__navbar__icon__dark"
          )
        }
        to='/signup' >
        <HiUserPlus onClick={closeMobileMenu}/>
      </Link>
      <Link className={theme === 'light' ? (
          "mobile__header__navbar__icon"
          ) : (
          "mobile__header__navbar__icon__dark"
          )
        }
      >
        <Spin className='mobile__header__navbar__icon__spin' toggled={isMobileMenuOpen} toggle={toggleMobileMenu} />
      </Link>
      {isMobileMenuOpen && (
        <>
          <div className={`mobile__header__overlay ${isMobileMenuOpen ? 'visible' : 'hidden'}`} onClick={toggleMobileMenu}></div>
          <div className={`mobile__header__menu ${isMobileMenuOpen ? 'open' : 'close'}`}>
            <Menu closeMobileMenu={closeMobileMenu} />
            <ThemeToggleButton />
          </div>
        </>
      )}
    </div>
  );
};

export default MobileMenu;