import React, { useRef } from "react";

import useResponsiveClassName from "../ResponsiveClassName.jsx";
import useDisableRightClick from "../DisableRightClick.jsx";

import imgPlanLeisure from '../../assets/img/imgPlan/img_plan_leisure.webp';
import imgPlanPerfection from '../../assets/img/imgPlan/img_plan_perfection.webp';
import imgPlanPassion from '../../assets/img/imgPlan/img_plan_passion.webp';


const plansData = {
  leisure: {
    id: "leisure",
    image: imgPlanLeisure,
    title: "Loisir",
    price: [
      "Frais d'inscription : 18 €",
      "1H00 - 380 €",
      "1H15 - 430 €",
      "1H30 - 480 €"
    ],
    description: "Ouvert aux enfants dès l’âge de 4 ans. Ce forfait vous offre la possibilité de participer à un cours de danse par semaine, adapté à votre niveau de compétence, pour explorer votre passion pour la danse à votre rythme. Les cours loisirs ont pour but d’amener des bases solides, de découvrir et d’apprendre. Un petit temps rien que pour vous.",
  },
  perfection: {
    id: "perfection",
    image: imgPlanPerfection,
    title: "Perfection",
    price: [
      "Frais d'inscription : 18 €",
      "2H00 - 590 €",
      "2H30 - 670 €",
      "3H00 - 750 €"
    ],
    description: "Le meilleur moyen de mieux danser ? C’est de danser davantage. Si vous voulez ressentir les progrès dans votre corps semaine après semaine; voir l’évolution concrète de votre technique et acquérir la liberté de mouvement liée à la connaissance et à la réalisation de pas complexes. Cette formule est faite pour vous. Des tarifs dégressifs vous permettent d’accèder à toutes vos envies de danse.",
  },
  passion: {
    id: "passion",
    image: imgPlanPassion,
    title: "Passion",
    price: [
      "Frais d'inscription : 18 €",
      "3H30 - 875 €",
      "Illimité - 1010 €"
    ],
    description: "Vous ne pouvez plus vous passez de danser ? Les séances régulières vous permettront de renforcer votre technique, d'améliorer votre condition physique et d'affiner votre expression artistique. Vous ferez partie d'un groupe passionné de danseurs, partageant votre amour pour la danse et votre désir de repousser vos limites artistiques. Rejoignez-nous dès aujourd'hui et plongez dans une aventure captivante du monde de la danse.",
  },
};


const ThumbPlan = ({ id }) => {
  const getClassName = useResponsiveClassName();
  const plan = plansData[id];

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  if (!plan) {
    console.error(`Plan with id "${id}" not found in plansData.`);
    return null; // or some fallback UI
  }

  const { image, title, price, description } = plan;

  return (
    <>
      <div id={id} className={getClassName(`booking__section__plan__thumbnail ${id}__thumbnail`)}>
        <div className={getClassName(`booking__section__plan__thumbnail__container ${id}__thumbnail__container`)}>
          <img className={getClassName(`booking__section__plan__thumbnail__container__image ${id}__thumbnail__container__image`)} src={image} alt={title} ref={imageRef} />
        </div>
        <h3 className={getClassName(`booking__section__plan__thumbnail__title ${id}__thumbnail__title`)}>{title}</h3>
        <div className={getClassName(`booking__section__plan__thumbnail__prices ${id}__thumbnail__prices`)}>
          {price && price.map((item, index) => (
            <p key={index} className={getClassName(`booking__section__plan__thumbnail__prices__item ${id}__thumbnail__prices__item`)}>
              {item}
            </p>
          ))}
        </div>
        <div className={getClassName(`booking__section__plan__thumbnail__txt ${id}__thumbnail__txt`)}>
          <p className={getClassName(`booking__section__plan__thumbnail__txt__description ${id}__thumbnail__txt__description`)}>
            {description}
          </p>
        </div>
      </div>
    </>

  );
};


export default ThumbPlan;