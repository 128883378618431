import { useMediaQuery } from "react-responsive";


const useResponsiveClassName = () => {
  const isFullScreen = useMediaQuery({ minWidth: 1921 });
  const isDesktop = useMediaQuery({ minWidth: 1025, maxWidth: 1920 });
  const isTablet = useMediaQuery({ minWidth: 769, maxWidth: 1024 });
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const geClassName = (baseClass) => {
    if (isFullScreen) return `fullscreen__${baseClass}`;
    if (isDesktop) return `desktop__${baseClass}`;
    if (isTablet) return `tablet__${baseClass}`;
    if (isMobile) return `mobile__${baseClass}`;

    return baseClass;
  }
  return geClassName;
};


export default useResponsiveClassName;