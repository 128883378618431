// ThemeToggleButton.js
import React from 'react';
import { Link } from 'react-router-dom';
import { HiMoon, HiSun } from 'react-icons/hi2';

import useResponsiveClassName from '../../ResponsiveClassName.jsx';
import { useTheme } from '../../ThemeContext.jsx';


const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useTheme();
  const getClassName = useResponsiveClassName();

  return (
    <Link onClick={toggleTheme} className={
      theme === 'light' ? (
        getClassName('header__user__icon')
      ) : (
        getClassName('header__user__icon__dark'
      ))}>
      {theme === 'light' ? 
        <HiMoon /> 
        :
        <HiSun />
      }
    </Link>
  );
};

export default ThemeToggleButton;