import React from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";
import Collapse from "../../components/Utils/Collapse/Collapse.jsx";


function GeneralInfos() {
  const getClassName = useResponsiveClassName();

  return (
    <div className={getClassName(`generalinfos__section`)}>
      <Collapse
        id="legalNotice"
      />
      <Collapse
        id="privacyPolicy"
      />
      <Collapse
        id="generalTermsOfUse"
      />
    </div>
  )
};


export default GeneralInfos;