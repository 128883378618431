import React, { useRef } from "react";

import useResponsiveClassName from "../ResponsiveClassName.jsx";
import useDisableRightClick from "../DisableRightClick.jsx";

import imgTecLogo from '../../assets/img/imgEvents/tec_logo.webp';
import imgTec1 from '../../assets/img/imgEvents/tec_img_1.webp';
import imgTec2 from '../../assets/img/imgEvents/tec_img_2.webp';
import imgTec3 from '../../assets/img/imgEvents/tec_img_3.webp';
import imgTec4 from '../../assets/img/imgEvents/tec_img_4.webp';
import imgTec5 from '../../assets/img/imgEvents/tec_img_5.webp';
import imgTec6 from '../../assets/img/imgEvents/tec_img_6.webp';
import imgFaLogo from '../../assets/img/imgEvents/fa_logo.webp';
import imgFa1 from '../../assets/img/imgEvents/fa_img_1.webp';
import imgFa2 from '../../assets/img/imgEvents/fa_img_2.webp';
import imgFa3 from '../../assets/img/imgEvents/fa_img_3.webp';
import imgFa4 from '../../assets/img/imgEvents/fa_img_4.webp';
import imgFaAffiche from '../../assets/img/imgEvents/fa_affiche.webp';


const eventsData = {
  tousEnCuisine: {
    id: "tousEnCuisine",
    year: 2023,
    logo: imgTecLogo,
    images: [
      imgTec1,
      imgTec2,
      imgTec3,
      imgTec4,
      imgTec5,
      imgTec6,
    ]
  },
  femmesdAIXception: {
    id: "femmesdAIXception",
    year: 2024,
    logo: imgFaLogo,
    images: [
      imgFa1,
      imgFa2,
      imgFaAffiche,
      imgFa3,
      imgFa4,
    ]
  }
};


const ThumbEvents = ({ id }) => {
  const getClassName = useResponsiveClassName();
  const { year, logo, images } = eventsData[id];

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  if (!eventsData[id]) {
    return (
      <div>Invalid event ID</div>
    );
  }

  return (
    <>
      <div id={id} className={getClassName(`section__event__thumbnail thumbnail__${id}`)}>
        <h2 className={getClassName(`section__event__thumbnail__title  thumbnail__${id}__title`)}>{year}</h2>
        <div className={getClassName(`section__event__thumbnail__container thumbnail__${id}__container`)}>
          <img className={getClassName(`section__event__thumbnail__container__image thumbnail__${id}__container__image`)} src={logo} alt={id} ref={imageRef} />
        </div>
        <div className={getClassName(`section__event__thumbnail__portfolio thumbnail__${id}__portfolio`)}>
          {images && images.map(
            (image, index) => (
              <div className={getClassName(`section__event__thumbnail__portfolio__image`)}>
                <img key={index} className={getClassName(`section__event__thumbnail__portfolio__image__item thumbnail__${id}__portfolio__image__item__${index}`)} src={image} alt={`${id}__image__${index}`} ref={imageRef} />
              </div>
            )
          )}
        </div>
      </div>
    </>
  )
};


export default ThumbEvents;