import React, { useState, useEffect } from 'react';
import { HiChevronUp } from "react-icons/hi2";

import useResponsiveClassName from '../../ResponsiveClassName.jsx';


const ScrollToTop = () => {
  const getClassName = useResponsiveClassName();
  const [isVisible, setIsVisible] = useState(false);

    // Listen to scroll event to show/hide button
  useEffect(() => {
    const handleScroll = () => {
      const headerHeight = document.querySelector('header').offsetHeight;
      const currentScroll = window.scrollY;
      setIsVisible(currentScroll > headerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

    // Function to return to top of page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <div className={getClassName('scrolltotop')}>
      {isVisible && (
        <button className={getClassName('scrolltotop__button')} onClick={scrollToTop}>
          <HiChevronUp className={getClassName('scrolltotop__button__chevron')} />
        </button>
      )}
    </div>
  );
};


export default ScrollToTop;
