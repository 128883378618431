import React from "react";

import Soon from "../../components/Soon/Soon.jsx";

function SignUp() {
  return (
    <>
      <Soon />
    </>
  )
};


export default SignUp;