import React from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";

import ThumbShow from "../../components/Thumbs/ThbShow.jsx";
import ThumbEvents from "../../components/Thumbs/ThbEvents.jsx";


function Events() {
  const getClassName = useResponsiveClassName();

  return (
    <section className={getClassName(`wrapper__events`)}>
      <ThumbShow
       id="lanouvelle"
      />
      <ThumbShow
       id="heritage"
      />
      <ThumbEvents
        id="tousEnCuisine"
      />
      <ThumbEvents
        id="femmesdAIXception"
      />
    </section>
  )
};


export default Events;