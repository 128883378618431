import React, { useRef } from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";
import useDisableRightClick from "../../components/DisableRightClick.jsx";

import imgSchool1 from '../../assets/img/imgSchool/img_school_1.webp';
import imgSchool2 from '../../assets/img/imgSchool/img_school_2.webp';
import imgSchool3 from '../../assets/img/imgSchool/img_school_3.webp';
import imgSchool4 from '../../assets/img/imgSchool/img_school_4.webp';
import imgCharte from '../../assets/img/imgSchool/img_charte.webp';


function School() {
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  return (
    <div className={getClassName(`school__section`)}>
      <section className={getClassName(`school__section__offices`)}>
        <h2 className={getClassName(`school__section__offices__title`)}>NOS LOCAUX</h2>
        <div className={getClassName(`school__section__offices__container`)}>
          <img className={getClassName(`school__section__offices__container__image`)} src={imgSchool1} alt={imgSchool1} ref={imageRef} />
          <img className={getClassName(`school__section__offices__container__image`)} src={imgSchool2} alt={imgSchool2} ref={imageRef} />
          <img className={getClassName(`school__section__offices__container__image`)} src={imgSchool3} alt={imgSchool3} ref={imageRef} />
          <img className={getClassName(`school__section__offices__container__image`)} src={imgSchool4} alt={imgSchool4} ref={imageRef} />
        </div>
        <div className={getClassName(`school__section__offices__txt`)}>
          <q className={getClassName(`school__section__offices__txt__citation`)}>
            &nbsp; Les grands danseurs ne sont pas grands à cause de leur technique,
            <br />
            ils sont grands à cause de leur passion. &nbsp;
          </q>
          <p className={getClassName(`school__section__offices__txt__author`)}>
            Martha GRAHAM
          </p>
        </div>
      </section>
      <section className={getClassName(`school__section__ethics`)}>
        <h2 className={getClassName(`school__section__ethics__title`)}>NOTRE CHARTE ETHIQUE</h2>
        <div className={getClassName(`school__section__ethics__container`)}>
          <img className={getClassName(`school__section__ethics__container__image`)} src={imgCharte} alt={imgCharte} ref={imageRef} />
        </div>
      </section>
      <section className={getClassName(`school__section__view`)}>
        <h2 className={getClassName(`school__section__view__title`)}>NOTRE VISION</h2>
        <p className={getClassName(`school__section__view__values`)}>Donner la possibilité de découvrir la culture de la danse.
          <br />
          L’école organise des sorties pour aller voir des spectacles de danse, achète des livres sur l’univers de la danse qu’elle met à disposition des élèves, des vidéos de ballet sont diffusés à l’entrée de l’école et des projets artistiques sont mis en place hors des murs de l’école afin d’amener la culture de la danse sous toutes ses formes.</p>
        <div className={getClassName(`school__section__view__txt`)}>
          <p  className={getClassName(`school__section__view__txt__explains`)}>La danse apparaît comme un outil puissant pour apaiser, rassembler. La danse nous invite à explorer nos émotions, à nous connecter avec les autres. Offrir du réconfort, du courage et de la confiance en soi. Utiliser la danse pour développer sa capacité à exprimer ses émotions, gérer son stress et améliorer sa connaissance de soi.</p>
        </div>
      </section>
    </div>
  )
};


export default School;