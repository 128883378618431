import React from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";

import ThumbExtras from "../../components/Thumbs/ThbExtras.jsx";


function Extras() {
  const getClassName = useResponsiveClassName();

  return (
    <section className={getClassName('wrapper__extras')}>
      <ThumbExtras
        id="competition"
        customClass="extras-page"
      />
      <ThumbExtras
        id="wedding"
        customClass="extras-page"
      />
      <ThumbExtras
        id="lesson"
        customClass="extras-page"
      />
    </section>
  )
};


export default Extras;