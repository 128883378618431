import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import useResponsiveClassName from '../../ResponsiveClassName.jsx';
import useDisableRightClick from '../../DisableRightClick.jsx';
import { useTheme } from '../../ThemeContext.jsx';

import LogoGrey from '../../../assets/logo/logo_grey.svg';
import LogoWhite from '../../../assets/logo/logo_white.svg';

import SubMenu from '../../../components/Utils/Menus/SubMenu.jsx';
import BackButton from '../Buttons/BackButton.jsx';


const Menu = ({ closeMobileMenu }) => {
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  const { theme } = useTheme();

  const [isDisciplinesOpen, setIsDisciplinesOpen] = useState(false);
  const [isExtrasOpen, setIsExtrasOpen] = useState(false);
  const [isInfosBookingOpen, setIsInfosBookingOpen] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const [isAboutOpen, setIsAboutOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 768 });

    // Status management when mouseover & mouseclicking is enabled
  const handleMouseEnter = (setter) => {
    if (!isMobile) setter(true);
  };
  const handleMouseLeave = (setter) => {
    if (!isMobile) setter(false);
  };
  const handleMenuClick = (setter, currentState) => {
    if (isMobile) {
      setter(!currentState);
      setIsMobileMenuOpen(!isMobileMenuOpen);
    }
  };

  const handleLinkClick = () => {
    if (isMobile && closeMobileMenu) {
      setIsDisciplinesOpen(false);
      setIsExtrasOpen(false);
      setIsInfosBookingOpen(false);
      setIsNewsOpen(false);
      setIsAboutOpen(false);
      closeMobileMenu();
    }
  };


  return (
    <div className={getClassName('header__nav__menu')}>
      <div className={getClassName('header__nav__logo__formobile')} to='/'>
        {theme === 'light' ? (
          <img className={getClassName('header__nav__logo__formobile__img')} src={LogoWhite} alt='Logo 6th Art gris' title="Logo 6th Art" ref={imageRef} />
          ) : (
          <img className={getClassName('header__nav__logo__formobile__img')} src={LogoGrey} alt='Logo 6th Art blanc' title="Logo 6th Art" ref={imageRef} />
          )
        }
      </div>
      <div
        className={getClassName('header__nav__menu__item')}
        onMouseEnter={() => handleMouseEnter(setIsDisciplinesOpen)}
        onMouseLeave={() => handleMouseLeave(setIsDisciplinesOpen)}
        onClick={() => handleMenuClick(setIsDisciplinesOpen, isDisciplinesOpen)}
      >
        <Link to='/disciplines'>DISCIPLINES</Link>
        {isDisciplinesOpen && (
          <div className={getClassName(`header__nav__submenu__container ${isMobileMenuOpen ? 'open' : 'close'}`)}>
            <SubMenu id="kindergarden" title="Jardin d'éveil" items={[]} path="/disciplines" onClick={handleLinkClick} />
            <SubMenu id="ballet" title="Classique" items={[]} path="/disciplines" onClick={handleLinkClick} />
            <SubMenu id="jazz" title="Modern' Jazz" items={[]} path="/disciplines" onClick={handleLinkClick} />
            <SubMenu id="contemporary" title="Contemporain" items={[]} path="/disciplines" onClick={handleLinkClick} />
            <SubMenu id="floor-bar" title="Barre à terre" items={[]} path="/disciplines" onClick={handleLinkClick} />
            <div
              className={getClassName('header__nav__menu__item__extras')}
              onMouseEnter={() => handleMouseEnter(setIsExtrasOpen)}
              onMouseLeave={() => handleMouseLeave(setIsExtrasOpen)}
              onClick={() => handleMenuClick(setIsExtrasOpen, isExtrasOpen)}
            >
              <Link className={getClassName('header__nav__menu__item__extras__title')} to="/extras" onClick={handleLinkClick}>Les p'tits +</Link>
              {isExtrasOpen && (
                <div className={getClassName(`header__nav__submenu__container__extras ${!isMobileMenuOpen ? 'open' : 'close'}`)}>
                  <SubMenu id="competition" title="Concours" items={[]} path="/extras" onClick={handleLinkClick} />
                  <SubMenu id="wedding" title="Mariage" items={[]} path="/extras" onClick={handleLinkClick} />
                  <SubMenu id="lesson" title="Cours particulier" items={[]} path="/extras" onClick={handleLinkClick} />
                </div>
              )}
            </div>
            <BackButton />
          </div>
        )}
      </div>
      <div
        className={getClassName('header__nav__menu__item')}
        onMouseEnter={() => handleMouseEnter(setIsInfosBookingOpen)}
        onMouseLeave={() => handleMouseLeave(setIsInfosBookingOpen)}
        onClick={() => handleMenuClick(setIsInfosBookingOpen, isInfosBookingOpen)}
      >
        <Link to='/infosbooking'>INFOS & RESERVATIONS</Link>
        {isInfosBookingOpen && (
          <div className={getClassName(`header__nav__submenu__container ${isMobileMenuOpen ? 'open' : 'close'}`)}>
            <SubMenu id="planning" title="Planning" items={[]} path='/infosbooking' onClick={handleLinkClick} />
            <SubMenu id="plan" title="Formules" items={[]} path='/infosbooking' onClick={handleLinkClick} />
            <SubMenu id="register" title="S'inscrire" items={[]} path='/infosbooking' onClick={handleLinkClick} />
            <BackButton />
          </div>
        )}
      </div>
      <Link className={getClassName('header__nav__logo')} to='/'>
        {theme === 'light' ? (
          <img className={getClassName('header__nav__logo__img')} src={LogoGrey} alt='Logo 6th Art gris' title="Logo 6th Art" ref={imageRef} />
          ) : (
          <img className={getClassName('header__nav__logo__img')} src={LogoWhite} alt='Logo 6th Art blanc' title="Logo 6th Art" ref={imageRef} />
          )
        }
      </Link>
      <div
        className={getClassName('header__nav__menu__item')}
        onMouseEnter={() => handleMouseEnter(setIsNewsOpen)}
        onMouseLeave={() => handleMouseLeave(setIsNewsOpen)}
        onClick={() => handleMenuClick(setIsNewsOpen, isNewsOpen)}
      >
        ACTUALITES
        {isNewsOpen && (
          <div className={getClassName(`header__nav__submenu__container ${isMobileMenuOpen ? 'open' : 'close'}`)}>
            <SubMenu title="Evènements" items={[]} path='/events' onClick={handleLinkClick} />
            <SubMenu title="Workshop" items={[]} path='/workshop' onClick={handleLinkClick} />
            <BackButton />
          </div>
        )}
      </div>
      <div
        className={getClassName('header__nav__menu__item')}
        onMouseEnter={() => handleMouseEnter(setIsAboutOpen)}
        onMouseLeave={() => handleMouseLeave(setIsAboutOpen)}
        onClick={() => handleMenuClick(setIsAboutOpen, isAboutOpen)}
      >
        A PROPOS
        {isAboutOpen && (
          <div className={getClassName(`header__nav__submenu__container ${isMobileMenuOpen ? 'open' : 'close'}`)}>
            <SubMenu title="Qui suis-je ?" items={[]} path='/whoiam' onClick={handleLinkClick} />
            <SubMenu title="L'école" items={[]} path='/school' onClick={handleLinkClick} />
            <SubMenu title="Contactez-nous" items={[]} path='/contact' onClick={handleLinkClick} />
            <BackButton />
          </div>
        )}
      </div>
      <Link className={getClassName('header__nav__menu__item')} to='/store' onClick={handleLinkClick}>BOUTIQUE</Link>
    </div>
  );
};


export default Menu;