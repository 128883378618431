import { Link } from "react-router-dom";
import { HiUserPlus, HiShoppingBag } from "react-icons/hi2";

import ThemeToggleButton from "../Buttons/ThmTgleBtn.jsx";

import { useTheme } from '../../ThemeContext.jsx';
import useResponsiveClassName from "../../ResponsiveClassName.jsx";


const UserMenu = () => {
  const getClassName = useResponsiveClassName();
  const { theme } = useTheme();


  return (
    <>
      <nav className={getClassName('header__user')}>
        <Link className={theme === 'light' ? (
            getClassName('header__user__icon')
            ) : (
            getClassName('header__user__icon__dark')
            )
          }
          to='/signup' title="signup" >
          <HiUserPlus />
        </Link>
        <Link className={theme === 'light' ? (
            getClassName('header__user__icon')
            ) : (
              getClassName('header__user__icon__dark')
            )
          }
          to='/cart' title="cart" >
          <HiShoppingBag />
        </Link>
        <div className={getClassName('header__user__icon')} title="switch theme">
          <ThemeToggleButton />
        </div>
      </nav>
    </>
  )
};


export default UserMenu;