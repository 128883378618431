import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HiArrowUturnLeft } from "react-icons/hi2";

import useResponsiveClassName from '../../ResponsiveClassName.jsx';


const BackButton = () => {
  const getClassName = useResponsiveClassName();

  const navigate = useNavigate();

  const handleBackClick = () => {
      navigate(-1);
  };


  return (
    <button className={getClassName('back-button')} onClick={handleBackClick}>
      <HiArrowUturnLeft />
    </button>
  );
};

export default BackButton;