import React from "react";

import Soon from "../components/Soon/Soon.jsx";

function Cart() {
  return (
    <>
      <Soon />
    </>
  )
};


export default Cart;