import React, { useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";
import useDisableRightClick from "../../components/DisableRightClick.jsx";

import imgPlanning from '../../assets/img/imgPlan/img_planning.webp';
import InscriptionFolder from '../../assets/media/inscription-folder.pdf';

import ThumbsPlan from "../../components/Thumbs/ThbPlan.jsx";


function InfosBooking() {
  const getClassName = useResponsiveClassName();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  const toggleLogin = () => {
    setIsLoggedIn(!isLoggedIn);
  };


  return (
    <div className={getClassName('booking__section')}>
      <section id="planning" className={getClassName('booking__section__planning')}>
        <h2 className={getClassName('booking__section__planning__title')}>Planning</h2>
        <div className={getClassName('booking__section__planning__container')}>
          <img
            className={getClassName('booking__section__planning__container__image')}
            src={imgPlanning}
            alt={imgPlanning}
            title="Planning 2024-2025"
            ref={imageRef}
          />
        </div>
      </section>
      <section id="plan" className={getClassName('booking__section__plan')}>
        <h2 className={getClassName('booking__section__plan__title')}>Formules</h2>
        <div className={getClassName('booking__section__plan__thumbs')}>
          <ThumbsPlan
            id="leisure"
          />
          <ThumbsPlan
            id="perfection"
          />
          <ThumbsPlan
            id="passion"
          />
        </div>
      </section>
      <section id="register" className={getClassName('booking__section__register')}>
        <h2 className={getClassName('booking__section__register__title')}>S'inscrire</h2>
        <div className={getClassName(`booking__section__register__container`)}>
          <article className={getClassName('booking__section__register__container__details')}>
            <p className={getClassName('booking__section__register__container__details__txt')}>Merci de prendre contact avec l’école afin de vous assurer s’il reste de la place dans le cours souhaité. Les places sont limitées par cours. Les demandes seront traitées par ordre d’arrivée.<br />
            <br />
            Après avoir vérifié, vous pouvez vous connecter pour télécharger le formulaire ci-contre.
            <br />
            <br />
            Vous pouvez expédier votre dossier d’inscription par voie postale, de le déposer directement dans la boite aux lettres de l’école ou de le remettre en main propre lors des horaires d’ouvertures.</p>
          </article>
          <aside className={getClassName('booking__section__register__container__infos')}>
            <div className={getClassName('booking__section__register__container__infos__inscriptionfolder')}>
              {isLoggedIn && (
                <a 
                  href={InscriptionFolder}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={getClassName('booking__section__register__container__infos__inscriptionfolder__item')}
                >
                  <FaFilePdf />
                </a>
              )}
              <button className={getClassName('booking__section__register__container__infos__inscriptionfolder__button')} onClick={toggleLogin}>
                {isLoggedIn ? 'Se déconnecter' : `Dossier d'inscription`}
              </button>
            </div>
            <div className={getClassName('booking__section__register__container__infos__address')}>
              <p className={getClassName('booking__section__register__container__infos__address__txt')}>
                Ecole de danse 6th Art <br />
                74 chemin du martinet <br />
                73420 - Drumettaz-Clarafond
              </p>
            </div>
          </aside>
        </div>
      </section>
    </div>
  )
};


export default InfosBooking;