import React, { useRef } from "react";

import useResponsiveClassName from "../ResponsiveClassName.jsx";
import useDisableRightClick from "../DisableRightClick.jsx";

import imgSoon from '../../assets/img/img_soon.webp';


function Soon() {
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  return (
    <section className={getClassName(`soon__section`)}>
      <div className={getClassName(`soon__section__container`)}>
        <img className={getClassName(`soon__section__container__image`)} src={imgSoon} alt={imgSoon} ref={imageRef} />
      </div>
      <h1 className={getClassName(`soon__section__title`)}>PROCHAINEMENT</h1>
    </section>
  );
};


export default Soon;