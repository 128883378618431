import { useEffect } from 'react';


function useDisableRightClick(ref) {
  useEffect(() => {
    // Capturer la valeur actuelle de ref.current
    const element = ref.current;

    const handleContextMenu = (event) => event.preventDefault();

    if (element) {
      element.addEventListener('contextmenu', handleContextMenu);
    }

    return () => {
      if (element) {
        element.removeEventListener('contextmenu', handleContextMenu);
      }
    };
  }, [ref]);
}


export default useDisableRightClick;