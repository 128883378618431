import React, { useRef } from "react";
import PropTypes from "prop-types";

import useResponsiveClassName from "../ResponsiveClassName.jsx";
import useDisableRightClick from "../DisableRightClick.jsx";

import imgEveil from '../../assets/img/imgDiciplines/img_disciplines_eveil.webp';
import imgJazz from '../../assets/img/imgDiciplines/img_disciplines_jazz.webp';
import imgContempo from '../../assets/img/imgDiciplines/img_disciplines_contemporain.webp';
import imgBallet from '../../assets/img/imgDiciplines/img_disciplines_classique.webp';
import imgBat from '../../assets/img/imgDiciplines/img_disciplines_bat.webp';


const disciplinesData = {
  kindergarden: {
    id: 'kindergarden',
    image: imgEveil,
    title: "Jardin d'éveil",
    description: "Le cours d’éveil permet aux enfants la découverte de leurs corps grâce à des exercices courts, variés et ludiques. En créant et en inventant à partir de leurs imaginaires, ils prendront toujours plus conscience des mouvements naturels et spontanés qui leurs permettront d’acquérir une notion de danse précise."
  },
  ballet: {
    id: "ballet",
    image: imgBallet,
    title: "Classique",
    description: "La danse classique, cet art qui est souvent synonyme de grâce, de fluidité, de légèreté mais aussi de tenue. Pour atteindre cette aisance, on apprend à se placer (l’en dehors, l’alignement tête cage thoracique, bassin...) mais aussi les bases techniques les plus simples (le plié, le dégagé..) comme les plus complexes (la pirouette, le grand jeté...) ainsi qu’un vocabulaire approprié."
  },
  jazz: {
    id: "jazz",
    image: imgJazz,
    title: "Modern' Jazz",
    description: "Le Modern’ Jazz est un mélange de deux courants : le Jazz et la danse Moderne. Elle s’appuie grandement sur le rythme et les nuances. Chaque partie du corps est utilisée de façon isolée, ou fluide. Le Modern’ Jazz est une danse dynamique et pleine d’énergie qui demande néanmoins l’apprentissage d’une technique sérieuse et appliquée, en travaillant notamment sur les notions de contractions et de relâchement. Les cours sont composés d’exercices au milieu, de déplacements, d’un ensemble de pas techniques correspondant à la terminologie de la discipline pour aboutir à des chorégraphies."
  },
  contemporary: {
    id: "contemporary",
    image: imgContempo,
    title: "Contemporain",
    description: "La danse Contemporaine est une forme d’exploration des potentialités des mouvements du corps. Elle est caractérisée par ses fondamentaux et s’organise autour de l’espace, du temps, du poids et du flux des mouvements. Fluidité, tonicité, contrastes, relation à l’espace, changement d’état de corps sont les notions fondatrices de cette discipline. Le renforcement du centre est une clé essentielle, grâce à une maîtrise musculaire plus consciente. Les cours démarrent avec un travail au sol puis à la verticale au milieu, suivi de déplacements, improvisations et préparations de chorégraphie."
  },
  'floor-bar': {
    id: "floor-bar",
    image: imgBat,
    title: "Barre à terre",
    description: "La barre à terre consiste à renforcer sa musculature dans un contexte d'étirement, en maîtrisant sa respiration. Les étirements se font dans le relâchement et la souplesse s’acquiert petit à petit. La barre à terre est un très bon complément au cours de danse. Il permet de comprendre les mécanismes complexes de la technicité de la danse classique (l’allongement des muscles, la rotation de l en-dehors, la tonification ciblé des muscles déterminants pour progresser, la posture longiligne et élancée qui aident au maintien du corps…). La réalisation des exercices au sol délivre des contraintes de la gravité, en permettant d'isoler les parties du corps, de libérer les tensions et de trouver un bien-être dans le travail. La position allongée protège des blessures du dos tout en permettant de travailler son alignement et sa musculature."
  }
};


const ThumbDisciplines = ({ id, customClass }) => {
  const getClassName = useResponsiveClassName();
  const { image, title, description } = disciplinesData[id];

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  return (
    <div id={id} className={getClassName(`section__disciplines__thumbnail__${customClass} ${customClass}__${id}`)}>
      <div className={getClassName(`section__disciplines__thumbnail__${customClass}__container ${customClass}__${id}__container`)} >
        <img className={getClassName(`section__disciplines__thumbnail__${customClass}__container__image ${customClass}__${id}__container__image`)} src={image} alt={title} ref={imageRef} />
      </div>
      <div className={getClassName(`section__disciplines__thumbnail__${customClass}__container__txt ${customClass}__${id}__container__txt`)}>
        <h3 className={getClassName(`section__disciplines__thumbnail__${customClass}__container__title ${customClass}__${id}__container__title`)}>{title}</h3>
        <p className={getClassName(`section__disciplines__thumbnail__${customClass}__container__description ${customClass}__${id}__container__description`)}>{description}</p>
      </div>
    </div>
  );
};

ThumbDisciplines.propTypes = {
  id: PropTypes.string.isRequired,
  customClass: PropTypes.string,
};


export default ThumbDisciplines;