import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import '../src/styles/main.scss';

import { ThemeProvider } from './components/ThemeContext.jsx';
import ScrollTo from './components/ScrollTo.jsx';
import ScrollToTop from './components/Utils/ScrollToTop/ScrollToTop.jsx';

import Header from '../src/components/Utils/Header/Header.jsx';
import Banner from '../src/components/Utils/Banner/Banner.jsx';
import Footer from '../src/components/Utils/Footer/Footer.jsx';

import Home from '../src/pages/Home/Home.jsx';
import SignUp from './pages/SignUp/SignUp.jsx';
import Disciplines from '../src/pages/Disciplines/Disciplines.jsx';
import Extras from '../src/pages/Extras/Extras.jsx';
import InfosBooking from '../src/pages/infosBooking/InfosBooking.jsx';
import Events from '../src/pages/Events/Events.jsx';
import Workshop from '../src/pages/Workshop.jsx';
import WhoIAm from '../src/pages/WhoIAm/WhoIAm.jsx';
import School from '../src/pages/School/School.jsx';
import ContactUs from '../src/pages/ContactUs/ContactUs.jsx';
import Store from '../src/pages/Store.jsx';
import Cart from '../src/pages/Cart.jsx';
import GeneralInfos from '../src/pages/GeneralInfos/GeneralInfos.jsx';
import Error404 from '../src/pages/Error404/Error404.jsx';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <main>
        <Router>
          <ScrollTo />
          <Header />
          <Banner />
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route path='/signup' exact element={<SignUp />} />
            <Route path='/disciplines' exact element={<Disciplines />} />
            <Route path='/extras' exact element={<Extras />} />
            <Route path='/infosbooking' exact element={<InfosBooking />} />
            <Route path='/events' exact element={<Events />} />
            <Route path='/workshop' exact element={<Workshop />} />
            <Route path='/whoiam' exact element={<WhoIAm />} />
            <Route path='/school' exact element={<School />} />
            <Route path='/contact' exact element={<ContactUs />} />
            <Route path='/store' exact element={<Store />} />
            <Route path='/cart' exact element={<Cart />} />
            <Route path='/generalinfos' exact element={<GeneralInfos />} />
            <Route path='*' exact element={<Error404 />} />
          </Routes>
          <ScrollToTop />
          <Footer />
        </Router>
      </main>
    </ThemeProvider>
  </React.StrictMode>
);