import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';


const ScrollTo = ({ offset = 0 }) => {
  const { hash, pathname } = useLocation();

  useLayoutEffect(() => {
    const scrollToElement = () => {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const elementRect = element.getBoundingClientRect();
        const elementHeight = elementRect.height;
        const viewportHeight = window.innerHeight;

        const elementPosition = elementRect.top + window.scrollY;
        const centerOffset = (viewportHeight / 2) - (elementHeight / 2);
        const offsetPosition = elementPosition - centerOffset - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      } else {
        setTimeout(() => {
          scrollToElement();
        }, 500);
      }
    };

    // Attendre que le DOM soit complètement chargé
    requestAnimationFrame(() => {
      setTimeout(scrollToElement, 100);
    });
  }, [hash, pathname, offset]);

  return null;
};


export default ScrollTo;