import React from "react";

import Soon from "../components/Soon/Soon.jsx";


function Workshop() {
  return (
    <>
      <Soon />
    </>
  )
};


export default Workshop;