import React from "react";

import useResponsiveClassName from "../../components/ResponsiveClassName.jsx";

function Error404() {
  const getClassName = useResponsiveClassName();

  return (
    <section className={getClassName(`error404__section`)}>
      <div className={getClassName(`error404__section__title`)}>
        <h1 className={getClassName(`error404__section__title__number`)}>404</h1>
        <p className={getClassName(`error404__section__title__txt`)}>Oups ! La Page que vous cherchez n'existe pas.
          <br />
          Il semble que la page que vous essayez de consulter n'existe pas ou a été déplacée.
        </p>
      </div>
      <div className={getClassName(`error404__section__list`)}>
        <div className={getClassName(`error404__section__list__container`)}>
          <h3 className={getClassName(`error404__section__list__container__titre`)}>Que faire maintenant ?</h3>
          <li className={getClassName(`error404__section__list__container__link`)}>Retourner à l'Accueil : Revenez à la page d'accueil pour découvrir nos dernières actualités et informations sur nos cours.</li>
          <li className={getClassName(`error404__section__list__container__link`)}>Explorer nos Disciplines : Découvrez nos cours de danse en visitant la page Disciplines.</li>
          <li className={getClassName(`error404__section__list__container__link`)}>Contactez-nous : Si vous avez besoin d'aide, n'hésitez pas à nous contacter via notre page Contact ou à nous appeler au [numéro de téléphone].</li>
        </div>
        <div className={getClassName(`error404__section__list__container`)}>
          <h3 className={getClassName(`error404__section__list__container__titre`)}>Navigation Rapide</h3>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a className={getClassName(`error404__section__list__container__link__item`)} href="/">Accueil</a>
          </li>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a className={getClassName(`error404__section__list__container__link__item`)} href='/disciplines'>Disciplines</a>
          </li>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a className={getClassName(`error404__section__list__container__link__item`)} href='/infosbooking'>Infos & Réservations</a>
          </li>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a className={getClassName(`error404__section__list__container__link__item`)} href='/events'>Actualités</a>
          </li>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a className={getClassName(`error404__section__list__container__link__item`)} href='/contact'>Contact</a>
          </li>
        </div>
        <div className={getClassName(`error404__section__list__container`)}>
          <h3 className={getClassName(`error404__section__list__container__titre`)}>Suivez-nous sur les Réseaux Sociaux</h3>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a
              className={getClassName(`error404__section__list__container__link__item`)}
              href="https://www.facebook.com/6th.art73"
              target="_blank"
              rel="noopener noreferrer"
            >
                Facebook
            </a>
          </li>
          <li className={getClassName(`error404__section__list__container__link`)}>
            <a
              className={getClassName(`error404__section__list__container__link__item`)}
              href="https://www.instagram.com/6th.art73/"
              target="_blank"
              rel="noopener noreferrer"
            >
                Instagram
            </a>
          </li>
        </div>
      </div>
      <div className={getClassName(`error404__section__goodbye`)}>
        <p className={getClassName(`error404__section__goodbye__txt`)}>Merci de votre compréhension et à bientôt sur 6th Art !</p>
      </div>
    </section>
  )
};


export default Error404;