import { useState } from "react";
import { Link } from "react-router-dom";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";


const SubMenu = ({ id, title, items, path, onClick }) => {
  const getClassName = useResponsiveClassName();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <Link
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      className={getClassName('header__nav__submenu')}
      to={`${path}#${id}`}
      onClick={onClick}
    >
      <div className={getClassName('header__nav__submenu__title')}> {title} </div>
      {isOpen && (
        <div className={getClassName('header__nav__submenu__items')}>
          {items.map((item, index, onClick) => (
            <Link
              key={index}
              className={getClassName('header__nav__submenu__item')}
              to={`${path}#${id}-${index}`}
              onClick={onClick}
              >
                {item}
            </Link>
          ))}
        </div>
      )}
    </Link>
  );
};


export default SubMenu;