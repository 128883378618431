import React, { useRef, useState, useEffect} from "react";
import { useLocation } from "react-router-dom";

import useResponsiveClassName from "../../ResponsiveClassName.jsx";
import useDisableRightClick from "../../DisableRightClick.jsx";
import Slideshow from "./SlideShow.jsx";

import imgBannerDisciplines from '../../../assets/img/imgBanner/banner_discipline_img.webp';
import imgBannerExtras from '../../../assets/img/imgBanner/banner_extras_img.webp';
import imgBannerInfosBooking from '../../../assets/img/imgBanner/banner_infobooking_img.webp';
import imgBannerEvents from '../../../assets/img/imgBanner/banner_events_img.webp';
import imgBannerWorkshop from '../../../assets/img/imgBanner/banner_workshop_img.webp';
import imgBannerWhoIAm from '../../../assets/img/imgBanner/banner_whoiam_img.webp';
import imgBannerSchool from '../../../assets/img/imgBanner/banner_school_img.webp';
import imgBannerContact from '../../../assets/img/imgBanner/banner_contact_img.webp';
import imgBannerCGU from '../../../assets/img/imgBanner/banner_cgu_img.webp';
import imgBannerStore from '../../../assets/img/imgBanner/banner_store_img.webp';


function Banner() {
  const getClassName = useResponsiveClassName();

  const imageRef = useRef(null);
  useDisableRightClick(imageRef);

  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);


  const renderBanner = () => {
    switch (currentPath) {
      case "/":
        return (
          <>
            <Slideshow />
          </>
        );
      case "/disciplines":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Nos Disciplines</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerDisciplines} alt='bannière de page' title="bannière image disciplines" ref={imageRef} />
          </section>
        );
      case "/extras":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Les p'tits +</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerExtras} alt='bannière de page' title="bannière image les ptits plus" ref={imageRef} /> 
          </section>
        );
      case "/infosbooking":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Infos & Réservations</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerInfosBooking} alt='bannière de page' title="bannière image infos et réservation" ref={imageRef} />
          </section>
        );
      case "/events":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Evènements</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerEvents} alt='bannière de page' title="bannière image evènements" ref={imageRef} />
          </section>
        );
      case "/workshop":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Workshop</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerWorkshop} alt='bannière de page' title="bannière image workshop" ref={imageRef} />
          </section>
        );
      case "/whoiam":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Qui suis-je ?</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerWhoIAm} alt='bannière de page' title="bannière image qui suis-je ?" ref={imageRef} />
          </section>
        );
      case "/school":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>L' Ecole</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerSchool} alt='bannière de page' title="bannière image école" ref={imageRef} />
          </section>
        );
      case "/contact":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Contact</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerContact} alt='bannière de page' title="bannière image contact" ref={imageRef} />
          </section>
        );
      case "/signup":
        return (
          null
        );
      case "/store":
        return (
          <section className={getClassName('banner__section')}>
            <h1 className={getClassName('banner__section__title')}>Boutique</h1>
            <img className={getClassName('banner__section__img')} src={imgBannerStore} alt='bannière de page' title="bannière image boutique" ref={imageRef} />
          </section>
        );
      case "/cart":
        return (
          null
        );
      case "/generalinfos":
        return (
          <section className={getClassName('banner__section')}>
            <img className={getClassName('banner__section__img')} src={imgBannerCGU} alt='bannière de page' title="bannière image CGV" ref={imageRef} />
          </section>
        );
      default:
        return (
          null
        );
    }
  };

  return (
    <>
      { renderBanner() }
    </>
  );
  
};  


export default Banner;